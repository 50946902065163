import { useContext, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Container, Avatar, Button } from '@mui/material';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { UserContext } from '../../context/UserContext';
import { adminsAPI } from '../../apis/adminsAPI';
import { useEffectOnce } from '../../utils/UseEffectOnce';

import { Label, SubTitleLabel } from '../../components/Label';
import Panel from '../../components/Panel';
import TextInput from '../../components/TextInput';
import RoundCornerButton from '../../components/Button';
import CopyrightBar from '../../components/CopyrightBar';
import AlertMessage from '../../components/AlertMessage';
import moment from 'moment/moment';

const Background = styled('div')(() => ({
  height: '90vh',
  backgroundImage: 'url(/images/background.jpg)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export default function OTP() {
  const navigate = useNavigate();
  const { setLoading, setUser, setNavTitle, setAvailablePages } =
    useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [expiredTime, setExpiredTime] = useState(0);
  const severity = useRef(null);
  const { state } = useLocation();
  console.log(state);

  useEffectOnce(() => {
    if (!state?.email) {
      navigate('/login');
    }
    setExpiredTime(state.expiredTime);
  });

  const handleClickVerify = async () => {
    if (otpCode === '') {
      severity.current = 'error';
      setAlert('Please enter OTP Code');
    } else {
      setLoading(true);

      const response = await adminsAPI.verifyOTPCode('cms', { code: otpCode });
      const user = response?.admin;
      const availablePages = response?.availablePages;

      if (response) {
        setLoading(false);

        setUser(user);
        setAvailablePages(availablePages);

        navigate('/', { replace: true, state: {} });
        setNavTitle('首頁');
      } else {
        setLoading(false);
        severity.current = 'error';
        setAlert('驗證碼錯誤');
      }
    }
  };

  const handleClickResentOTPCode = async () => {
    const response = await adminsAPI.getOTPCode('cms');
    if (response) {
      setExpiredTime(response.expiredTime);
      severity.current = 'success';
      setAlert('重新寄送成功');
    } else {
      severity.current = 'error';
      setAlert('驗證碼寄送失敗');
    }
  };

  return (
    <Background>
      <Box display="flex" justifyContent="center" sx={{ marginTop: '50px' }}>
        <Avatar
          sx={{
            width: 50,
            height: 50,
            bgcolor: '#FF8E1533',
          }}>
          <LockPersonIcon color="primary" />
        </Avatar>
      </Box>

      <Label
        fontSize="25px"
        sx={{
          marginTop: '18px',
          marginBottom: '18px',
          textAlign: 'center',
          color: (theme) => theme.palette.secondary.main,
        }}>
        請輸入 OTP 驗證碼
      </Label>

      <Container maxWidth="xs">
        <Panel>
          <SubTitleLabel marginBottom="20px">
            為確保資訊安全，請於3分鐘內輸入發送至您的信箱 {state?.email}{' '}
            的6位驗證碼
          </SubTitleLabel>
          <Label marginBottom="10px" color="#b94c4c" fontSize="12px">
            {moment.unix(parseInt(expiredTime)).format('LT')}{' '}
            後驗證碼將失效，屆時請重新傳送驗證碼
          </Label>
          <TextInput
            placeholder="Enter OTP"
            gutterBottom
            value={otpCode}
            maxLength={6}
            setValue={setOtpCode}
          />
          <Button
            variant="text"
            fullWidth
            color="secondary"
            style={{ color: grey[400] }}
            sx={{
              textTransform: 'none',
              marginTop: '-20px',
              marginBottom: '30px',
            }}
            onClick={handleClickResentOTPCode}>
            重新傳送驗證碼
          </Button>
          <RoundCornerButton onClick={handleClickVerify}>
            SEND
          </RoundCornerButton>
        </Panel>
      </Container>

      <CopyrightBar />

      {/* Alert */}
      <AlertMessage
        alertState={{ alert, setAlert, severity: severity.current }}
      />
    </Background>
  );
}
