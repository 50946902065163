import React, { useState, useContext, useRef } from 'react';
import { Button, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useEffectOnce } from '../../utils/UseEffectOnce';
import { UserContext } from '../../context/UserContext';
import { adminsAPI } from '../../apis/adminsAPI';
import StyledDataGrid from '../../components/StyledDataGrid';
import UserDialog from './components/UserDialog';
import AlertMessage from '../../components/AlertMessage';

export default function UserManagement() {
  const { setLoading } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [alert, setAlert] = useState('');
  const severity = useRef(null);
  const [adminUser, setAdminUser] = useState(null);

  useEffectOnce(() => {
    refreshAdminUsersData();
  }, [users]);

  const refreshAdminUsersData = async () => {
    setLoading(true);
    const adminUsers = await adminsAPI.getAdmins();
    if (adminUsers) {
      setUsers(adminUsers);
    }
    setLoading(false);
  };

  /* 
    // action column button handler
    const ActionEdit = ({ user }) => {
        const handleEditClick = () => {
            setAdminUser(user);
            setOpenDialog(true);
        };

        return (
            <FormControlLabel
                control={
                    <IconButton
                        onClick={handleEditClick}
                        sx={{ marginLeft: "30px", color: "#BBB" }}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                }
            />
        );
    };
    */

  const handleEvent = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    setAdminUser(params.row);
    setOpenDialog(true);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'chineseName',
      headerName: 'Name',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 240,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'employeeCode',
      headerName: 'Employee ID',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'group',
      headerName: 'Group',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'permission',
      headerName: 'Permission',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'level',
      headerName: 'Level',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    /*
        // action column
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div
                        className='d-flex justify-content-between align-items-center'
                        style={{ cursor: "pointer" }}
                    >
                        <ActionEdit user={params.row} />
                    </div>
                );
            },
        },
        */
  ];

  const userDialogCompletion = (result) => {
    severity.current = result?.error ? 'error' : 'success';
    setAlert(result?.msg);

    if (!result?.error) {
      refreshAdminUsersData();
    }
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: '20px' }}>
        <Button
          variant="text"
          sx={{ marginLeft: 2, marginTop: 0, marginBottom: 2 }}
          startIcon={<AddIcon />}
          onClick={() => {
            setAdminUser(null);
            setOpenDialog(true);
          }}>
          new user
        </Button>

        <Button
          variant="text"
          sx={{ marginLeft: 4, marginTop: 0, marginBottom: 2 }}
          startIcon={<RefreshIcon />}
          color="secondary"
          onClick={() => {
            refreshAdminUsersData();
          }}>
          refresh
        </Button>

        <div style={{ height: '80vh', width: '100%' }}>
          <StyledDataGrid
            rows={users}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // checkboxSelection
            disableSelectionOnClick
            onRowClick={handleEvent}
            sx={{
              backgroundColor: '#FEFEFE',
              // boxShadow: 1,
            }}
          />
        </div>
      </Container>

      <UserDialog
        adminUser={adminUser}
        setAdminUser={setAdminUser}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        completion={userDialogCompletion}
      />

      {/* Alert */}
      <AlertMessage
        alertState={{ alert, setAlert, severity: severity.current }}
      />
    </>
  );
}
