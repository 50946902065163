import React from 'react';
import { Container } from '@mui/material';
import Panel from '../../components/Panel';
import { Label } from '../../components/Label';

export default function Accounting() {
  return (
    <Container maxWidth="md" sx={{ marginTop: '40px' }}>
      <Panel>
        <Label>哀哀，不會要把帳務系統移過來吧！</Label>
      </Panel>
    </Container>
  );
}
