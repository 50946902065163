export function priceNumberFormat(value) {
  if (!value) {
    value = 0;
  }
  let decimalScale = 0;
  let fixedDecimalScale = true;
  if (value < 0.01) {
    decimalScale = 4;
    fixedDecimalScale = false;
  } else if (value < 0.1) {
    decimalScale = 3;
    fixedDecimalScale = false;
  } else if (value < 100) {
    decimalScale = 2;
    if (value < 1) fixedDecimalScale = false;
  }

  const formattedValue = value.toFixed(decimalScale);
  const [integerPart, fractionalPart = ''] = formattedValue.split('.');

  if (fixedDecimalScale) {
    return thousandSeparator(formattedValue);
  } else {
    const trimmedFractionalPart = fractionalPart.replace(/0+$/, '');
    return `${thousandSeparator(integerPart)}${
      trimmedFractionalPart ? '.' + trimmedFractionalPart : ''
    }`;
  }
}

export function priceNumberFormatToInt(value) {
  if (!value) {
    value = 0;
  }
  let decimalScale = 0;
  let fixedDecimalScale = true;
  if (value < 0.01) {
    decimalScale = 4;
    fixedDecimalScale = false;
  } else if (value < 0.1) {
    decimalScale = 3;
    fixedDecimalScale = false;
  } else if (value < 1) {
    decimalScale = 2;
    fixedDecimalScale = false;
  }

  const formattedValue = value.toFixed(decimalScale);
  const [integerPart, fractionalPart = ''] = formattedValue.split('.');

  if (fixedDecimalScale) {
    return thousandSeparator(formattedValue);
  } else {
    const trimmedFractionalPart = fractionalPart.replace(/0+$/, '');
    return `${thousandSeparator(integerPart)}${
      trimmedFractionalPart ? '.' + trimmedFractionalPart : ''
    }`;
  }
}

export function priceNumberRoundedToInt(value) {
  if (!value || typeof value !== 'number') {
    value = 0;
  }
  let decimalScale = 0;

  if (value < 0.01 && value > 0) {
    decimalScale = 4;
  } else if (value < 0.1) {
    decimalScale = 3;
  } else if (value < 1) {
    decimalScale = 2;
  }
  const formattedValue = Number(value.toFixed(decimalScale));
  return formattedValue;
}

function thousandSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
