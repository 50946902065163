import { TitleLabel } from '../../../components/Label';
import Panel from '../../../components/Panel';
import CustomGrid from '../../../components/CustomGrid';
import {
  Box,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  CircularProgress,
} from '@mui/material';
import { useState, useContext } from 'react';
import CustomerDialog from './CustomerDialog';
import { UserContext } from '../../../context/UserContext';
import { customerAPI } from '../../../apis/customerAPI';
import { useEffectOnce } from '../../../utils/UseEffectOnce';
import {
  priceNumberFormatToInt,
  priceNumberRoundedToInt,
} from '../../../utils/priceNumberFormat';

export default function CustomerAsset({ id }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [asset, setAsset] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  let ntdAsset = 0;
  let digitalAsset = 0;
  let tableData = [];

  asset.totalAssets?.forEach((item) => {
    if (item.currencyId === 'TWD') {
      ntdAsset += item.availableBalance;
    } else {
      tableData.push({
        cryptoType: item.currencyId,
        amount: item.balance,
        twdValue: item.twdValue,
      });
      digitalAsset += priceNumberRoundedToInt(item.twdValue);
    }
  });

  const totalAsset = digitalAsset + ntdAsset;
  const assetData = [
    { title: '帳戶總資產(TWD)', value: priceNumberFormatToInt(totalAsset) },
    { title: '台幣帳戶餘額(TWD)', value: priceNumberFormatToInt(ntdAsset) },
    { title: '數位資產(TWD)', value: priceNumberFormatToInt(digitalAsset) },
    {
      title: '門市入金限額',
      value: `單筆${asset.orderLimitAmount / 10000}萬/單月${
        asset.monthlyLimitAmount / 10000
      }萬`,
    },
    {
      title: '本月門市入金可用餘額',
      value: priceNumberFormatToInt(asset.remainingAmount),
    },
  ];

  useEffectOnce(() => {
    const fetchAccounts = async () => {
      setIsFetching(true);
      const response = await customerAPI.getUserAccounts(id);
      if (response !== null) {
        setAsset(response);
      }
      setIsFetching(false);
    };

    fetchAccounts();
  }, [setIsFetching, id]);

  const handleEvent = () => {
    setOpenDialog(true);
  };
  return (
    <>
      {isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <>
          <Panel gutterBottom>
            <CustomGrid data={assetData.slice(0, 3)} />
          </Panel>

          <Panel gutterBottom>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <TitleLabel>數位資產總覽</TitleLabel>
              <Button variant="text" size="large" onClick={handleEvent}>
                資產紀錄
              </Button>
            </Box>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>幣種</TableCell>
                  <TableCell align="center">數量</TableCell>
                  <TableCell align="right">台幣價值</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data) => (
                  <TableRow
                    key={data.cryptoType}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}>
                    <TableCell component="th" scope="row">
                      {data.cryptoType}
                    </TableCell>
                    <TableCell align="center">{data.amount}</TableCell>
                    <TableCell align="right">
                      {priceNumberFormatToInt(data.twdValue)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Panel>

          <Box sx={{ marginBottom: 4 }}>
            <Panel>
              <CustomGrid data={assetData.slice(3)} />
            </Panel>
          </Box>
        </>
      )}

      <CustomerDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        monthlyAssets={asset.monthlyAssets}
      />
    </>
  );
}
