import { useState, useEffect, useMemo, useContext } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import ImageUploader from './ImageUploader';
import { ImageContext } from '../pages/CustomerInfo/context/ImageContext';
import { customerAPI } from '../apis/customerAPI';
import { UserContext } from '../context/UserContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IDFDReview from './IDFDReview';

import { IDFD_SUMMARY, IDFD_IMAGES} from '../mock/idfd_data';


function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ maxWidth: 'lg', border: 'solid 1px' }}>
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UserImage({
  orderNumber,
  value,
  setValue,
  hiddenUploadBtn,
  DisableTabSwitch,
}) {
  const { setLoading } = useContext(UserContext);
  const [uploadedKYCImage, setUploadedKYCImage] = useState([]);
  const [uploadedAMLImage, setUploadedAMLImage] = useState([]);
  const [disableKycButton, setDisableKycButton] = useState(false);
  const [disableAmlButton, setDisableAmlButton] = useState(false);
  const [idfdImage, setIdfdImage] = useState([]);
  const [idfdSummary, setIdfdSummary] = useState({});
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const imageContextValue = useMemo(
    () => ({
      kyc: {
        uploadedKYCImage,
        setUploadedKYCImage,
      },
      aml: {
        uploadedAMLImage,
        setUploadedAMLImage,
      },
      idfd: {
        summary: {
          idfdSummary,
          setIdfdSummary,
        },
        images: {
          idfdImage,
          setIdfdImage,
        },
      },
    }),
    [
      uploadedKYCImage,
      setUploadedKYCImage,
      uploadedAMLImage,
      setUploadedAMLImage,
      idfdSummary,
      setIdfdSummary,
      idfdImage,
      setIdfdImage,
    ],
  );

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const componentLists = [
    {
      title: 'KYC 證明文件圖檔',
      value: (
        <ImageUploader
          orderNumber={orderNumber}
          imageType={'KYC'}
          hiddenUploadBtn={hiddenUploadBtn}
          disabled={disableKycButton}
        />
      ),
    },
    {
      title: 'AML 證明文件圖檔',
      value: (
        <ImageUploader
          orderNumber={orderNumber}
          imageType={'AML'}
          hiddenUploadBtn={hiddenUploadBtn}
          disabled={disableAmlButton}
        />
      ),
    },
  ];

  useEffect(() => {
    async function fetchImageData() {
      setLoading(true);
      const payload = { identityVerificationId: orderNumber };
      const response = await customerAPI.getImageData(payload);
      setLoading(false);
      if (response?.error?.message) {
        setOpen(true);
        setAlertMessage(response.error.message);
      } else {
        setDisableKycButton(response.kyc.disable);
        setDisableAmlButton(response.aml.disable);
        setUploadedKYCImage(response.kyc.files);
        setUploadedAMLImage(response.aml.files);
        setIdfdSummary(response?.idfd?.summary);
        setIdfdImage(response?.idfd?.files);
      }
    }

    fetchImageData();
  }, [
    setLoading,
    orderNumber,
    setDisableKycButton,
    setDisableAmlButton,
    setUploadedKYCImage,
    setUploadedAMLImage,
    setIdfdSummary,
    setIdfdImage
  ]);

  return (
    <ImageContext.Provider value={imageContextValue}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example">
          {componentLists.map((item, index) => (
            <Tab
              disabled={DisableTabSwitch && value !== index}
              key={item.title}
              label={item.title}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
            />
          ))}
        </Tabs>

        <Box sx={{ marginTop: '10px' }}>
          {componentLists.map((item, index) => (
            <TabPanel value={value} index={index} key={index}>
              {item.value}
            </TabPanel>
          ))}
        </Box>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {value === 0 && <IDFDReview summary={idfdSummary} images={idfdImage} reviewType="riskReview"/>}
    </ImageContext.Provider>
  );
}
