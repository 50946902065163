import React from 'react';
import { Container } from '@mui/material';
import Panel from '../../components/Panel';
import { Label } from '../../components/Label';

export default function PushNotificationService() {
  return (
    <Container maxWidth="md" sx={{ marginTop: '40px' }}>
      <Panel>
        <Label>嘿嘿！發送推播嘍～</Label>
      </Panel>
    </Container>
  );
}
