import { useState, useEffect, useContext, memo } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Box, Pagination } from '@mui/material';
import FileUploadButton from './FileUploadButton';
import ImageModal from './ImageModal';
import { ImageContext } from '../pages/CustomerInfo/context/ImageContext';
import Panel from './Panel';
import { uploadImageAPI } from '../apis/uploadImageAPI';
import { UserContext } from '../context/UserContext';
import AlertMessage from './AlertMessage';
import useIdentityVerificationImageBase64 from '../hooks/useIdentityVerificationImageBase64';

export default function ImageUploader({
  orderNumber,
  imageType,
  hiddenUploadBtn,
  disabled,
}) {
  const image_count_per_page = process.env.REACT_APP_CMS_RISK_REVIEW_IMAGES_COUNT_PER_PAGE;
  const { kyc, aml } = useContext(ImageContext);
  const { setLoading } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState('');
  let pageCount;
  if (imageType === "KYC") {
    pageCount = Math.ceil(kyc.uploadedKYCImage.length / image_count_per_page)
  } else if (imageType === "AML") {
    pageCount = Math.ceil(aml.uploadedAMLImage.length / image_count_per_page)
  }
  let start = (page-1)*image_count_per_page;
  let end = start + image_count_per_page;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  async function handleImageUpload(imageFile) {
    setLoading(true);
    const payload = {
      verificationCategory: imageType,
      identityVerificationId: orderNumber,
      files: imageFile,
    };
    const response = await uploadImageAPI.uploadImage(payload);
    setLoading(false);

    if (response?.error) {
      if (response.error.message.uploadMessage) {
        setAlert(response.error.message.uploadMessage)
        if (imageType === 'KYC') {
          kyc.setUploadedKYCImage(response.error.message.kycFiles)
        } else if (imageType === 'AML') {
          aml.setUploadedAMLImage(response.error.message.amlFiles)
        }
      } else {
        setAlert(response.error.message)
      }
    } else {
      if (imageType === 'KYC') {
        kyc.setUploadedKYCImage(response.kycFiles);
      } else if (imageType === 'AML') {
        aml.setUploadedAMLImage(response.amlFiles);
      }
    }
  }

  function ImageItemWithModel({ title, subtitle, id, url, description }) {
    return (
      <ImageModal key={id} url={url} description={description}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#F0F0F0',
            padding: '10px',
            margin: '5px',
            border: 'solid #D0D0D0 1px',
            borderRadius: '8px',
          }}>
          <ImageListItem
            cols={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                width: '100px',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}>
              <img
                src={url}
                alt={description}
                loading="lazy"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  objectFit: 'contain',
                }}
              />
            </Box>
            <ImageListItemBar
              title={title}
              subtitle={subtitle}
              position="below"
              actionPosition="right"
              sx={{
                display: 'flex',
                marginLeft: '10px',
                maxWidth: '280px',
              }}
            />
          </ImageListItem>
        </Box>
      </ImageModal>
    );
  }

  const ImageBase64ItemWithModel= memo(function ImageBase64ItemWithModel({ title, subtitle, id, fileContentBs64, description }) {
    const payload = {file_path: fileContentBs64}
    const {isPending, imageBase64} = useIdentityVerificationImageBase64(payload)
    // const base64 = `data:image/jpg;base64,${imageBase64}`;
    return (
      <ImageModal key={id} url={imageBase64} description={description}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#F0F0F0',
            padding: '10px',
            margin: '5px',
            border: 'solid #D0D0D0 1px',
            borderRadius: '8px',
          }}>
          <ImageListItem
            cols={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                width: '100px',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}>
              <img
                src={imageBase64}
                alt={description}
                loading="lazy"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  objectFit: 'contain',
                }}
              />
            </Box>
            <ImageListItemBar
              title={title}
              subtitle={subtitle}
              position="below"
              actionPosition="right"
              sx={{
                display: 'flex',
                marginLeft: '10px',
                maxWidth: '280px',
              }}
            />
          </ImageListItem>
        </Box>
      </ImageModal>
    );
  })

  function RenderImageItemWithModel() {
    if (imageType === 'KYC') {
      return (
        kyc.uploadedKYCImage.length > 0 &&
        kyc.uploadedKYCImage.slice(start, end).map((item) => (
          <ImageBase64ItemWithModel
            key={item.name}
            title={item.name}
            subtitle={
              <span>
                {item.createdTime} 存入單號 {item.identityVerificationId}
              </span>
            }
            id={item.name}
            fileContentBs64={item.fileContentBs64}
            description={item.description}
          />
        ))
      );
    } else if (imageType === 'AML') {
      return (
        aml.uploadedAMLImage.length > 0 &&
        aml.uploadedAMLImage.slice(start, end).map((item) => (
          <ImageBase64ItemWithModel
            key={item.name}
            title={item.name}
            subtitle={
              <span>
                {item.createdTime} 存入單號 {item.identityVerificationId}
              </span>
            }
            id={item.name}
            fileContentBs64={item.fileContentBs64}
            description={item.description}
          />
        ))
      );
    } else {
      return [];
    }
  }

  useEffect(() => {
    // call API and save image to uploadedKYCImage
  }, []);

  return (
    <>
      <Panel>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FileUploadButton
            content={'新增圖檔'}
            type={'file'}
            onUpload={handleImageUpload}
            disabled={disabled}
            hiddenUploadBtn={hiddenUploadBtn}
          />
        </Box>
        <ImageList sx={{ width: '100%' }} cols={2} rowHeight={70} gap={3}>
          {RenderImageItemWithModel()}
        </ImageList>
        <Pagination count={pageCount} page={page} onChange={handlePageChange} />
      </Panel>
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </>
  );
}
