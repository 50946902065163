import { useState, useContext, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoGrid from './ui/InfoGrid';
import ImageDisplayer from './ui/ImageDisplayer';
import { customerAPI } from '../../../apis/customerAPI';
import { TitleLabel } from '../../../components/Label';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { UserContext } from '../../../context/UserContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IDFDReview from '../../../components/IDFDReview';
import { IDFD_SUMMARY, IDFD_IMAGES } from '../../../mock/idfd_data';
import { Pagination } from '@mui/material';

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '2px solid rgba(0, 0, 0, .125)',
}));

const CustomAccordion = styled(Accordion)(({ theme }) => {
  return {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)', // this styles directly apply to accordion
    paddingLeft: '20px',
    border: `1px solid #eee`,
    '.MuiAccordionDetails-root': {},
    '.MuiAccordionSummary-root': {}, // this apply to Summary
  };
});

export default function Review({
  orderNumber,
  disableKyc,
  disableAml,
  ekycData,
  ekycManualReviewData,
  amlData,
  amlManualReviewData,
}) {
  const image_count_per_page = process.env.REACT_APP_CMS_RISK_REVIEW_IMAGES_COUNT_PER_PAGE;
  const { setLoading } = useContext(UserContext);
  const [uploadedKYCImage, setUploadedKYCImage] = useState([]);
  const [uploadedAMLImage, setUploadedAMLImage] = useState([]);
  const [idfdSummary, setIdfdSummary] = useState({});
  const [idfdImages, setIdfdImages] = useState([]); 
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [kycPage, setKycPage] = useState(1);
  const [amlPage, setAmlPage] = useState(1);
  
  let kycTotalPage = Math.ceil(uploadedKYCImage.length / image_count_per_page);
  let kycStart = (kycPage-1)*image_count_per_page;
  let kycEnd = kycStart + image_count_per_page;

  let amlTotalPage = Math.ceil(uploadedAMLImage.length / image_count_per_page);
  let amlStart = (amlPage-1)*image_count_per_page;
  let amlEnd = amlStart + image_count_per_page;

  const handleKycPageChange = (event, value) => {
    setKycPage(value);
  };

  const handleAmlPageChange = (event, value) => {
    setAmlPage(value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    async function fetchImageData() {
      setLoading(true);
      const payload = { identityVerificationId: orderNumber };
      const response = await customerAPI.getImageData(payload);
      setLoading(false);
      if (response?.error?.message) {
        setOpen(true);
        setAlertMessage(response.error.message);
      } else {
        setUploadedKYCImage(response.kyc.files);
        setUploadedAMLImage(response.aml.files);
        setIdfdSummary(response.idfd.summary);
        setIdfdImages(response.idfd.files);
      }
    }

    fetchImageData();
  }, [orderNumber, setLoading, setUploadedKYCImage, setUploadedAMLImage]);
  return (
    <div>
      <IDFDReview summary={idfdSummary} images={idfdImages} reviewType="riskReviewConfirm"/>
      {
        <CustomAccordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            <Typography variant="subtitle1" fontSize="18px" fontWeight={500}>
              eKYC 審核
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize="18px" fontWeight={500}>
              基本資料
            </Typography>
            <InfoGrid data={ekycData} direction={'row'} gridWidth={6} />

            <Divider sx={{ marginY: '20px' }} />

            <TitleLabel fontSize="18px" fontWeight={'medium'}>
              證明文件圖檔
            </TitleLabel>
            <ImageDisplayer images={uploadedKYCImage.slice(kycStart, kycEnd)} />
            <Pagination count={kycTotalPage} page={kycPage} onChange={handleKycPageChange} />

            <Divider sx={{ marginY: '20px' }} />

            <TitleLabel fontSize="18px" fontWeight={'medium'}>
              人工審查內容
            </TitleLabel>
            <InfoGrid
              data={ekycManualReviewData}
              direction={'column'}
              gridWidth={12}
            />
          </AccordionDetails>
        </CustomAccordion>
      }
      {
        <CustomAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header">
            <Typography variant="subtitle1" fontSize="18px" fontWeight={500}>
              AML 審核
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize="18px" fontWeight={500}>
              基本資料
            </Typography>
            <InfoGrid data={amlData} direction={'row'} gridWidth={6} />

            <Divider sx={{ marginY: '20px' }} />
            <TitleLabel fontSize="18px" fontWeight={'medium'}>
              證明文件圖檔
            </TitleLabel>
            <ImageDisplayer images={uploadedAMLImage.slice(amlStart, amlEnd)} />
            <Pagination count={amlTotalPage} page={amlPage} onChange={handleAmlPageChange} />

            <Divider sx={{ marginY: '20px' }} />

            <TitleLabel fontSize="18px" fontWeight={'medium'}>
              人工審查內容
            </TitleLabel>
            <InfoGrid
              data={amlManualReviewData}
              direction={'column'}
              gridWidth={12}
            />
          </AccordionDetails>
        </CustomAccordion>
      }

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
