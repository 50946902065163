import { useContext, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { UserContext } from '../../context/UserContext';
import { adminsAPI } from '../../apis/adminsAPI';
import { customerAPI } from '../../apis/customerAPI';
import { useEffectOnce } from '../../utils/UseEffectOnce';

import { Label } from '../../components/Label';
import Panel from '../../components/Panel';
import TextInput from '../../components/TextInput';
import RoundCornerButton from '../../components/Button';
import CopyrightBar from '../../components/CopyrightBar';
import AlertMessage from '../../components/AlertMessage';

const Background = styled('div')(() => ({
  height: '90vh',
  backgroundImage: 'url(/images/background.jpg)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export default function Login() {
  const navigate = useNavigate();
  const { setLoading, setUser, setNavTitle, setAvailablePages } =
    useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const severity = useRef(null);
  const location = useLocation();

  useEffectOnce(() => {
    const queryUrl = location.search;
    const queryParams = new URLSearchParams(queryUrl);
    const data = queryParams.get('data');

    async function login(data) {
      setLoading(true);

      const response = await customerAPI.login({
        data: encodeURIComponent(data),
      });
      const user = response?.admin;
      const availablePages = response?.availablePages;

      if (user) {
        setLoading(false);

        setUser(user);
        setAvailablePages(availablePages);

        const query = response?.query;
        if (query !== null) {
          navigate('/customerService', { state: { query } });
          setNavTitle('會員資料查詢');
        } else {
          navigate('/');
          setNavTitle('首頁');
        }
      } else {
        setLoading(false);
        severity.current = 'error';
        setAlert('登入資料錯誤或逾期');
        navigate('/login');
      }
    }

    if (data) {
      login(data);
    }
  });

  const ignoreOtpEmail = [
    //'superadmin@taiwanmobile.com'
  ];

  const handleClickLogin = async () => {
    if (email === '') {
      severity.current = 'error';
      setAlert('Please enter email');
    } else if (password === '') {
      severity.current = 'error';
      setAlert('Please enter password');
    } else if (!ignoreOtpEmail.includes(email)) {
      // 讓不能收到OTP code的測試帳號可以直接登入
      const response = await adminsAPI.signin({
        email: email,
        password: password,
      });

      const user = response?.admin;
      const availablePages = response?.availablePages;

      if (user) {
        setLoading(false);

        setUser(user);
        setAvailablePages(availablePages);

        navigate('/', { replace: true, state: {} });
        setNavTitle('首頁');
      } else {
        setLoading(false);
        severity.current = 'error';
        setAlert('登入錯誤');
      }
    } else {
      setLoading(true);
      const response = await adminsAPI.signin({
        email: email,
        password: password,
      });
      const user = response?.admin;
      if (user) {
        const response = await adminsAPI.getOTPCode('cms');
        setLoading(false);
        if (response.expiredTime) {
          navigate('/otp', {
            state: {
              email: user.email,
              expiredTime: response.expiredTime,
            },
          });
        } else {
          severity.current = 'error';
          setAlert('登入錯誤');
        }
      } else {
        setLoading(false);
        severity.current = 'error';

        setAlert('登入錯誤');
      }
    }
  };

  const handleClickForgetPassword = async () => {
    setResetEmail('');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleResetPassword = async () => {
    if (resetEmail !== '') {
      setOpenDialog(false);

      setLoading(true);

      const response = await adminsAPI.forgotPassword({
        email: resetEmail,
      });

      setLoading(false);

      severity.current = 'success';
      setAlert('Done');
    } else {
      severity.current = 'error';
      setAlert('Please enter email');
    }
  };

  const resetEmailDialog = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={'xs'}
        fullWidth={true}>
        <DialogTitle>Forget password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            fullWidth
            autoComplete="off"
            variant="outlined"
            placeholder="Enter your email"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setResetEmail(e.target.value);
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleResetPassword}>Reset password</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Background>
      <Box display="flex" justifyContent="center" sx={{ marginTop: '50px' }}>
        <Avatar
          sx={{
            width: 50,
            height: 50,
            bgcolor: '#FF8E1533',
          }}>
          <LockPersonIcon color="primary" />
        </Avatar>
      </Box>

      <Label
        fontSize="25px"
        sx={{
          marginTop: '18px',
          marginBottom: '18px',
          textAlign: 'center',
          color: (theme) => theme.palette.secondary.main,
        }}>
        Login
      </Label>

      <Container maxWidth="xs">
        <Panel>
          <TextInput
            label="Email"
            placeholder="Enter your email"
            gutterBottom
            value={email}
            setValue={setEmail}
          />

          <TextInput
            label="Password"
            placeholder="Enter password"
            gutterBottom
            type="password"
            value={password}
            setValue={setPassword}
          />

          <Button
            variant="text"
            fullWidth
            color="secondary"
            style={{ fontSize: '10px', color: grey[400] }}
            sx={{
              textTransform: 'none',
              marginTop: '-20px',
              marginBottom: '30px',
            }}
            onClick={handleClickForgetPassword}>
            Forget password
          </Button>

          <RoundCornerButton onClick={handleClickLogin}>
            Login
          </RoundCornerButton>
        </Panel>
      </Container>

      <CopyrightBar />

      {/* Alert */}
      <AlertMessage
        alertState={{ alert, setAlert, severity: severity.current }}
      />

      {resetEmailDialog()}
    </Background>
  );
}
